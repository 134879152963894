import * as React from "react"
import Layout from "../components/Layout";
import SEO from "../components/seo";

export default () => {

    return (<Layout>
        <div id="content" className="animate-fade-in">
            <h1 className={'font-bold'}>Kooperationen</h1>
            <p>
                Durch die Kooperation mit dem Steuerbüro <a href={'https://bs-senden.de/'} target='_blank'>Dr. Bratke &
                Schwann GbR</a> biete ich Ihnen eine auf Ihre Bedürfnisse individuell zugeschnittene und
                umfassende sowie in sich abgestimmte Beratung, nachhaltige Betreuung und Vertretung. Beide Kanzleien
                befinden sich im selben Bürogebäude so dass kurze Wege bei fachübergreifender Beratung möglich sind.
            </p>
        </div>
    </Layout>)
};

export const Head = () => {
    return (<SEO title={'Kooperationen'}/>);
}
